<template>
  <b-overlay :show="formShow" rounded="true" :fixed="true" no-fade>
    <b-row>
      <b-alert show variant="info" dismissible class="p-1" style="width: 100%;">
        <span>The purpose of this chart:</span>
        <p>
          ⁍ It is to calculate the standard deviation of the observers who made the survey according to the selected survey.
          <br />
          ⁍ You can also filter and view the standard deviation calculations of the schools linked to the survey.
        </p>
      </b-alert>
    </b-row>
    <b-card>
      <b-row class="">
        <b-col cols="12" xl="4" lg="3" md="12" sm="12">
          <b-form-group>
            <v-select id="event" v-model="selectedEvent" :options="eventTypes" :reduce="(val) => val" :filterable="true" placeholder="Search by event" @option:selected="selectedEvents" label="title" :clearable="false" input-id="event" />
          </b-form-group>
        </b-col>
        <b-col cols="12" xl="4" lg="3" md="12" sm="12">
          <b-form-group>
            <v-select id="event" v-model="selectedSchool" :options="schools" :reduce="(val) => val" :filterable="true" placeholder="Search by school" @option:selected="filterBySchool" label="name" :clearable="false" input-id="event" />
          </b-form-group>
        </b-col>
        <b-col cols="12" xl="4" lg="3" md="12" sm="12">
          <b-form-group>
            <b-input-group>
              <flat-pickr :disabled="resultData.observers" placeholder="Date Range" v-model="rangeDate" onClose="testClose" class="form-control" :config="{ mode: 'range' }" />
              <b-input-group-append>
                <b-button @click="clearDate()" variant="warning">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col class="col-auto" cols="3" xl="3" md="12" sm="12">
          <b-form-group>
            <!-- <b-button @click="downloadPdf()" v-show="$Can('produce_a_report')" class="mr-2" block variant="outline-info" :disabled="!resultData.observers">
                  Generate Report
                </b-button> -->
          </b-form-group>
        </b-col>
      </b-row>

      <b-row align-h="start" class="mt-1 mb-3">
        <b-col class="col-auto" cols="9" xl="9" lg="9" md="12" sm="12">
          <!-- <div style="margin-bottom: 0.5rem;display: flex;justify-content: end">
            <feather-icon
              icon="HelpCircleIcon"
              v-b-tooltip.hover
              id="tooltip-button-show-event3"
            />
           
            <b-tooltip ref="tooltip-show" target="tooltip-button-show-event3">
              <span>The purpose of this chart:</span
              ><strong style="text-align: left;"
                ><br />⁍ It is to calculate the standard deviation of the
                observers who made the survey according to the selected survey.
                <br />
                ⁍ You can also filter and view the standard deviation
                calculations of the schools linked to the survey.</strong
              >
            </b-tooltip>
          </div> -->
          <canvas id="myChart"></canvas>
        </b-col>
        <b-col cols="12" xl="3" lg="3" md="12" sm="12">
          <table class="table table-bordered" small>
            <thead class="thead-dark text-center">
              <tr>
                <th scope="col" colspan="12">
                  Standard Deviation
                </th>
              </tr>
            </thead>
            <tbody class="text-center">
              <tr>
                <th colspan="3">Total Observer Score</th>
                <td colspan="1" class="bg-light-secondary">
                  {{ resultTableData.tcs !== null ? resultTableData.tcs : 'N/A' }}
                </td>
              </tr>

              <tr>
                <th colspan="3">Observer Benchmark</th>
                <td colspan="1" class="bg-light-secondary">
                  {{ resultTableData.cb !== null ? resultTableData.cb : 'N/A' }}
                </td>
              </tr>

              <tr>
                <th colspan="3">Mean/Average Score</th>
                <td colspan="1" class="bg-light-secondary">
                  <b style="color:red">{{ resultTableData.mas !== null ? resultTableData.mas : 'N/A' }}</b>
                </td>
              </tr>

              <tr>
                <th colspan="3">Standard Deviation</th>
                <td colspan="1" class="bg-light-secondary">
                  {{ resultTableData.sd !== null ? resultTableData.sd : 'N/A' }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>

      <hr />

      <b-row align-h="start">
        <b-col cols="12" xl="3" lg="3" md="4" sm="12">
          <b-form-group>
            <b-button type="submit" @click="showArchiveRecords()" variant="warning">
              {{ archive == 'N' ? 'Show with archive records' : 'Hide with archive records' }}
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <template #overlay v-if="download">
      <div class="text-center">
        <!-- <b-icon icon="stopwatch" font-scale="3" animation="cylon">
                
              </b-icon> -->
        <p id="cancel-label"><feather-icon icon="DownloadIcon" /> Report creating please wait...</p>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import { BCol, BRow, BTab, BTabs, BCard, BAlert, BLink, VBModal, VBTooltip, BTooltip } from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';
import ChartjsComponentBarChart from './../charts-components/ChartjsComponentBarChart.vue';
import { Chart } from 'chart.js';
//   import Chart from 'chart.js/auto';
import AnnotationPlugin from 'chartjs-plugin-annotation';

Chart.plugins.register(AnnotationPlugin);

import store from '@/store';
import settingsStoreModule from '../../settingsStoreModule';
import ImageCropper from '@core/components/image-cropper/ImageCropper.vue';
import { required, alphaNum, email } from '@validations';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us';
import router from '@/router';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import axiosIns from '@/libs/axios';
import _ from 'lodash';
import { t } from '@/@core/libs/i18n/utils';
import { backgroundColor } from 'echarts/lib/theme/dark';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { $themeColors } from '@themeConfig';

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,
    BTooltip,

    vSelect,
    Cleave,
    ToastificationContent,
    ImageCropper,
    ValidationProvider,
    ValidationObserver,
    flatPickr,

    ChartjsComponentBarChart,
  },

  directives: {
    Ripple,
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
  },

  props: ['StatTrigger'],

  data: function() {
    return {
      archive: 'N',
      resultData: {
        event: {
          groups: [],
        },
      },
      resultTableData: {
        tcs: null,
        cb: null,
        mas: null,
        sd: null,
        seaa: 0,
        sba: 0,
      },
      rangeDate: null,
      filterDateStart: null,
      filterDateEnd: null,
      baseURL: store.state.app.baseURL,
      required,
      alphaNum,
      email,
      mychart: null,
      resultTotalAvarageScore: 0,
      standardDeviation: {
        sd: 0,
        sd1: 0,
        sd2: 0,
        sd3: 0,
        sdm1: 0,
        sdm2: 0,
        sdm3: 0,
      },
      filterPerson: {
        bg: 0,
        sd1: 0,
        sd2: 0,
        sd3: 0,
        sdm1: 0,
        sdm2: 0,
        sdm3: 0,
      },
      dropdownLoading: false,
      download: false,
      formShow: false,
      schools: [],
      selectedSchool: null,
      groups: [],
      allGroupsData: [],
      filteredQuestions: [],
      selectedEvent: null,
      ans: null,
      summaryList: null,
      answerTypes: [1, 2, 3, 4, 'Not Observed'],
      eventTypes: [],
      refreshChart: false,
    };
  },
  mounted() {
    //this.getSurveyResults();

    let ctx = document.getElementById('myChart');
    this.myChart = new Chart(ctx, {});
  },

  created() {},

  watch: {
    rangeDate: {
      handler: function(val, before) {
        if (val != null) {
          if (val.includes('to')) {
            this.filterDateStart = this.rangeDate.split(' to ')[0];
            this.filterDateEnd = this.rangeDate.split(' to ')[1];

            this.selectedEvents();
          }
        }
      },
    },
    StatTrigger: {
      handler: function(val, before) {
        if (val === 'ObserverStandardDeviation') {
          this.getSurveyResults();
          this.resultTableData = {
            tcs: null,
            cb: null,
            mas: null,
            sd: null,
            seaa: 0,
            sba: 0,
          };

          this.standardDeviation = {
            sd: 0,
            sd1: 0,
            sd2: 0,
            sd3: 0,
            sdm1: 0,
            sdm2: 0,
            sdm3: 0,
          };

          this.filterPerson = {
            bg: 0,
            sd1: 0,
            sd2: 0,
            sd3: 0,
            sdm1: 0,
            sdm2: 0,
            sdm3: 0,
          };
        }
      },
    },
  },
  methods: {
    showArchiveRecords() {
      if (this.archive == 'Y') {
        this.archive = 'N';

        this.selectedEvents();
      } else {
        this.archive = 'Y';

        this.selectedEvents();
      }
    },
    creatingChart(labels, datasets) {
      let ctx = document.getElementById('myChart');

      this.myChart.destroy();

      this.myChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: labels,
          datasets: datasets,
        },
        //orhan
        options: {
          elements: {
            rectangle: {
              borderWidth: 1,
              borderSkipped: 'bottom',
            },
          },

          responsive: true,
          maintainAspectRatio: true,
          aspectRatio: 1 | 4,

          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: false,
                  color: 'rgba(200, 200, 200, 0.2)',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
                scaleLabel: {
                  display: false,
                },

                ticks: {
                  fontColor: '#6e6b7b',
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: 'rgba(200, 200, 200, 0.2)',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
                ticks: {
                  min: 0,
                  max: _.max([this.filterPerson.bg, this.filterPerson.sd1, this.filterPerson.sd2, this.filterPerson.sd3, this.filterPerson.sdm1, this.filterPerson.sdm2, this.filterPerson.sdm3]) + 1,
                  fontColor: '#6e6b7b',
                },
              },
            ],
          },
        },
      });
    },

    downloadPdf() {
      this.formShow = true;
      this.download = true;
      let testArray = [];
      let downFilteredQuestions = [];
      let groupAnsewrs = [];

      let aaadd = _(this.resultData.questionAnswers)
        .groupBy('questionId')
        .map((question, id, index) => {
          return question[0];
        })
        .value();

      this.resultData.event.groups.forEach((element) => {
        downFilteredQuestions = _.filter(aaadd, {
          groupId: element.id,
        });

        let count = 0;
        if (downFilteredQuestions.length > 0) {
          downFilteredQuestions.forEach((question) => {
            this.resultData.questionAndAnswers.forEach((answer) => {
              if (question.questionId === answer.questionId) {
                question.itemScore = answer.commentAnswersAvarage.toFixed(2);
                downFilteredQuestions[0].domainScore = ((count += answer.commentAnswersAvarage) / downFilteredQuestions.length).toFixed(2);
              }
            });
          });
        }

        testArray.push(downFilteredQuestions);
      });
      store
        .dispatch('settings/cpatPdf', {
          data: testArray,
          groups: this.resultData.event.groups,
        })
        .then((response) => {
          if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Profit Loss Statement.pdf');
            document.body.appendChild(link);
            link.click();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Report is created!',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
            this.download = false;
            this.formShow = false;
          } else {
            this.formShow = false;
            this.download = false;
          }
        })
        .catch((error) => {
          this.formShow = false;
          this.download = false;
        });
    },

    getLastEvent() {
      this.formShow = true;
      store
        .dispatch('settings/getLastEventItem', [])
        .then((res) => {
          if (res.data) {
            this.selectedEvent = res.data;
            this.selectedEvents(res.data);
          }
        })
        .catch((error) => {
          console.log(error);
          this.formShow = false;
        });
    },
    clearDate() {
      if (this.rangeDate != null && this.rangeDate != '') {
        this.filterDateStart = null;
        this.filterDateEnd = null;
        this.rangeDate = null;
        this.selectedEvents();
      }
    },
    filterBySchool() {
      this.selectedEvents(undefined);
    },
    getSurveyResults() {
      this.formShow = true;

      let filter = {
        event: this.selectedEvent ? this.selectedEvent : null,
        filterDate: {
          startDate: this.filterDateStart,
          endDate: this.filterDateEnd,
        },
        archive: this.archive,
      };

      store
        .dispatch('settings/getAllSurveyResults', filter)
        .then((res) => {

          if(res.data.length){
          this.summaryList = res.data[0].summary;
          this.totalRows = res.data.length;
          this.getLastEvent();
          res.data.forEach((element) => {
            this.eventTypes.push(element.event);
          });

          this.eventTypes = _.uniqBy(this.eventTypes, function(e) {
            return e.id;
          });
        }
          this.formShow = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectedEvents(event) {
      if (event != undefined) {
        this.selectedSchool = null;
      }

      let filter = {
        event: event == undefined ? this.selectedEvent : event,
        filterDate: {
          startDate: this.filterDateStart,
          endDate: this.filterDateEnd,
        },
        school: this.selectedSchool && this.selectedSchool !== 'All School' ? this.selectedSchool : null,
        archive: this.archive,
      };
      store
        .dispatch('settings/getSurveyStandardDeviation', filter)
        .then((res) => {
          this.resultData = res.data.data;
          let surveyTotalQues = 0;
          let totalaa = 0;
          let allQuestions = [];
          this.resultTotalAvarageScore = 0;

          this.resultTableData = {
            tcs: null,
            cb: null,
            mas: null,
            sd: null,
            seaa: 0,
            sba: 0,
          };

          this.standardDeviation = {
            sd: 0,
            sd1: 0,
            sd2: 0,
            sd3: 0,
            sdm1: 0,
            sdm2: 0,
            sdm3: 0,
          };

          this.filterPerson = {
            bg: 0,
            sd1: 0,
            sd2: 0,
            sd3: 0,
            sdm1: 0,
            sdm2: 0,
            sdm3: 0,
          };

          this.selectedEvent.groups.forEach((element) => {
            element.questions.forEach((ques) => {
              if (ques.status == 'A' && ques.commentAnswer != 'Not Observed') {
                allQuestions.push(ques);
              }
            });
          });

          //Standard deviation operation
          let standardDeviationArray = [];

          this.resultData.surveys.forEach((s) => {
            standardDeviationArray.push(s.totalAvarage);

            this.resultTableData.tcs += s.sumTotal;
            this.resultTableData.cb += s.sumTotal;
          });

          //Calculate total avarage score
          this.resultTotalAvarageScore = _.sum(standardDeviationArray) / this.resultData.surveys.length;

          //calculate standard deviation
          this.standardDeviation.sd = this.getStandardDeviation(standardDeviationArray);

          this.standardDeviation.sd1 = parseFloat((this.resultTotalAvarageScore + this.standardDeviation.sd).toFixed(2));
          this.standardDeviation.sd2 = parseFloat((this.resultTotalAvarageScore + this.standardDeviation.sd * 2).toFixed(2));
          this.standardDeviation.sd3 = parseFloat((this.resultTotalAvarageScore + this.standardDeviation.sd * 3).toFixed(2));

          this.standardDeviation.sdm1 = parseFloat((this.resultTotalAvarageScore - this.standardDeviation.sd).toFixed(2));
          this.standardDeviation.sdm2 = parseFloat((this.resultTotalAvarageScore - this.standardDeviation.sd * 2).toFixed(2));
          this.standardDeviation.sdm3 = parseFloat((this.resultTotalAvarageScore - this.standardDeviation.sd * 3).toFixed(2));

          //filter how many survey as standard deviation
          this.resultTotalAvarageScore = this.resultTotalAvarageScore.toFixed(2);
          this.resultData.surveys.forEach((s) => {
            if (s.totalAvarage > this.standardDeviation.sdm3 && s.totalAvarage < this.standardDeviation.sdm2) {
              this.filterPerson.sdm3 += 1;
            } else if (s.totalAvarage > this.standardDeviation.sdm2 && s.totalAvarage < this.standardDeviation.sdm1) {
              this.filterPerson.sdm2 += 1;
            } else if (s.totalAvarage > this.standardDeviation.sdm1 && s.totalAvarage < this.resultTotalAvarageScore) {
              this.filterPerson.sdm1 += 1;
            } else if (s.totalAvarage > this.resultTotalAvarageScore && s.totalAvarage < this.standardDeviation.sd1) {
              this.filterPerson.bg += 1;
            } else if (s.totalAvarage > this.standardDeviation.sd1 && s.totalAvarage < this.standardDeviation.sd2) {
              this.filterPerson.sd1 += 1;
            } else if (s.totalAvarage > this.standardDeviation.sd2 && s.totalAvarage < this.standardDeviation.sd3) {
              this.filterPerson.sd2 += 1;
            } else if (s.totalAvarage > this.standardDeviation.sd3) {
              this.filterPerson.sd3 += 1;
            }

            // if (s.totalAvarage > this.resultTotalAvarageScore && s.totalAvarage < this.standardDeviation.sd1) {
            //   this.filterPerson.bg += 1;
            // } else if (s.totalAvarage > this.standardDeviation.sd1 && s.totalAvarage < this.standardDeviation.sd2) {
            //   this.filterPerson.sd1 += 1;
            // } else if (s.totalAvarage > this.standardDeviation.sd2 && s.totalAvarage < this.standardDeviation.sd3) {
            //   this.filterPerson.sd2 += 1;
            // } else if (s.totalAvarage > this.standardDeviation.sd3) {
            //   this.filterPerson.sd3 += 1;
            // } else if (s.totalAvarage > this.standardDeviation.sdm1 && s.totalAvarage < this.standardDeviation.sdm3) {
            //   this.filterPerson.bg += 1;
            // } else if (s.totalAvarage > this.standardDeviation.sdm2 && s.totalAvarage < this.standardDeviation.sdm2) {
            //   this.filterPerson.sdm1 += 1;
            // } else if (s.totalAvarage > this.standardDeviation.sdm3 && s.totalAvarage < this.standardDeviation.sdm1) {
            //   this.filterPerson.sdm2 += 1;
            // } else if (s.totalAvarage < this.standardDeviation.sdm3) {
            //   this.filterPerson.sdm3 += 1;
            // }
          });

          let labels = [
            0,
            '',
            this.standardDeviation.sdm2 + ' (-2)',
            '',
            this.standardDeviation.sdm1 + ' (-1)',
            '',
            Number(this.resultTotalAvarageScore) + ' (0)',
            '',
            this.standardDeviation.sd1 + ' (+1)',
            '',
            this.standardDeviation.sd2 + ' (+2)',
            // "",
            // 5,
          ];

          let datasets = [
            {
              label: this.resultData.surveyName,
              data: [0, this.filterPerson.sdm3, 0, this.filterPerson.sdm2, 0, this.filterPerson.sdm1, 0, this.filterPerson.bg, 0, this.filterPerson.sd1, 0, this.filterPerson.sd2, 0, this.filterPerson.sd3],
              fill: true,
              borderColor: 'rgb(75, 192, 192)',
              tension: 0.1,
            },
          ];

          this.resultTableData.sd = this.standardDeviation.sd.toFixed(2);
          this.resultTableData.mas = this.resultTotalAvarageScore;

          this.creatingChart(labels, datasets);

          this.schools = [];
          this.schools.push('All School');

          if (res.data.data.schools != undefined) {
            res.data.data.schools.forEach((s) => {
              this.schools.push(s.schoolName);
            });
          }
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },
    getStandardDeviation(array) {
      const n = array.length;
      const mean = array.reduce((a, b) => a + b) / n;
      return Math.sqrt(array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n);
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.left-align .nav-tabs .nav-link {
  justify-content: left !important;
}
</style>
