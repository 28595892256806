<template>
  <b-overlay :show="formShow" rounded="true" :fixed="true" no-fade>
    <b-row>
      <b-alert show variant="info" dismissible class="p-1" style="width: 100%;">
        <span>The purpose of this chart:</span>
        <p>⁍ It gives the 12-month rates of the surveys conducted in the selected year.</p>
      </b-alert>
    </b-row>
    <b-row>
      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
        <b-row class="" align-h="between">
          <b-col cols="12" xl="3" lg="3" md="4" sm="12">
            <b-form-group>
              <b-button type="submit" class="mr-2" @click="showArchiveRecords()" variant="outline-warning">
                {{ archive == 'N' ? 'Show with archive records' : 'Hide with archive records' }}
              </b-button>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <v-select id="event" v-model="selectedYear" :options="years" :reduce="(val) => val" :filterable="true" placeholder="Search by Years" @option:selected="selectedYears" label="title" :clearable="false" input-id="event" />
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="!notFoundData" cols="12" xl="12" lg="12" md="12" sm="12">
            <vue-apex-charts ref="realtimeChart" type="bar" height="500" :options="columnChart.chartOptions" :series="columnChart.series" />
          </b-col>
          <b-col v-else cols="12" xl="12" lg="12" md="12" sm="12">
            <h2 class="text-center">{{ notFoundText }}</h2>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { BCol, BRow, BTab, BTabs, BCard, BAlert, BLink, VBModal, VBTooltip, BTooltip } from 'bootstrap-vue';
import 'animate.css';
import { $themeColors } from '@themeConfig';
import vSelect from 'vue-select';
import { ref, onUnmounted } from '@vue/composition-api';
import _ from 'lodash';
import flatPickr from 'vue-flatpickr-component';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import 'flatpickr/dist/flatpickr.css';
import settingsStoreModule from '../../settingsStoreModule';
import store from '@/store';
import VueApexCharts from 'vue-apexcharts';
import Ripple from 'vue-ripple-directive';

export default {
  components: {
    BTab,
    BTabs,
    BRow,
    BCol,
    BTooltip,
    VueApexCharts,
    vSelect,
    flatPickr,
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
  },

  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },

  props: ['StatTrigger'],

  data() {
    return {
      resultData: {
        event: {
          groups: [],
        },
      },
      selectedEvent: null,
      eventTypes: [],
      formShow: false,
      rangeDate: null,
      filterDateStart: null,
      filterDateEnd: null,
      notFoundText: '',
      archive: 'N',
      notFoundData: false,
      selectedYear: new Date().getFullYear(),
      columnChart: {
        series: [],
        chartOptions: {
          chart: {
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          colors: [],
          plotOptions: {
            bar: {
              columnWidth: '15%',
              colors: {
                backgroundBarColors: ['#f8d3ff', '#f8d3ff', '#f8d3ff', '#f8d3ff', '#f8d3ff', '#f8d3ff', '#f8d3ff', '#f8d3ff', '#f8d3ff', '#f8d3ff', '#f8d3ff', '#f8d3ff'],
                backgroundBarRadius: 10,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: true,
            position: 'top',
            fontSize: '14px',
            fontFamily: 'Montserrat',
            horizontalAlign: 'left',
          },
          stroke: {
            show: true,
            colors: ['transparent'],
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          xaxis: {
            categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
          },
          yaxis: {
            // opposite: isRtl,
            ticks: {
              stepSize: 1,
              min: 10,
              // max: 230,
              fontColor: '#6e6b7b',
            },
          },
          fill: {
            opacity: 1,
          },
        },
      },
    };
  },
  mounted() {
    //this.getGroupBasedStats();
    this.years;
  },
  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from({ length: year - 2000 }, (value, index) => 2001 + index);
    },
  },
  methods: {
    showArchiveRecords() {
      if (this.archive == 'Y') {
        this.archive = 'N';

        this.getGroupBasedStats();
      } else {
        this.archive = 'Y';

        this.getGroupBasedStats();
      }
    },
    getGroupBasedStats() {
      this.formShow = true;
      const self = this;
      let filter = {
        year: this.selectedYear,
        archive: this.archive,
      };
      store
        .dispatch('settings/getGroupBasedStats', filter)
        .then((res) => {
          if (res.data.length > 0) {
            this.notFoundData = false;
            this.columnChart.series = [];
            res.data.forEach((event) => {
              this.columnChart.chartOptions.colors.push(event.chartColour);
              this.columnChart.series.push(event);
            });
            this.formShow = false;
          } else {
            this.formShow = false;
            this.notFoundText = 'Data not found for this year.';
            this.notFoundData = true;
          }
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    selectedYears() {
      this.formShow = true;
      const self = this;
      let filter = {
        year: this.selectedYear,
        archive: this.archive,
      };
      store
        .dispatch('settings/getGroupBasedStats', filter)
        .then((res) => {
          if (res.data.length > 0) {
            this.notFoundData = false;
            this.columnChart.series = [];
            // this.$refs.realtimeChart.resetSeries();

            res.data.forEach((event) => {
              this.columnChart.chartOptions.colors.push(event.chartColour);

              this.columnChart.series.push(event);
            });
            this.formShow = false;
          } else {
            this.formShow = false;
            this.notFoundText = 'Data not found for this year.';
            this.notFoundData = true;
          }
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },
  },
  watch: {
    StatTrigger: {
      handler: function(val, before) {
        if (val === 'GroupBasedStat') {
          this.getGroupBasedStats();
        }
      },
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.set-icon-css .input-group {
  justify-content: end;
}
</style>
