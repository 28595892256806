<template>
  <b-overlay :show="formShow" rounded="true" :fixed="true" no-fade>
    <b-row>
      <b-alert show variant="info" dismissible class="p-1" style="width: 100%;">
        <span>The purpose of this chart:</span>
        <p>⁍ It shows the average of the scores given by the observers who made the selected survey.<br />⁍ At the same time, the observers below and above the average are indicated in the table.</p>
      </b-alert>
    </b-row>
    <b-card>
      <!-- <b-row>
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col" class="bg-primary text-white">
                Observer Stats
              </th>
            </tr>
          </thead>
        </table>
      </b-row> -->

      <b-row class="">
        <b-col cols="12" xl="4" lg="3" md="12" sm="12">
          <b-form-group>
            <v-select id="event" v-model="selectedEvent" :options="eventTypes" :reduce="(val) => val" :filterable="true" placeholder="Search by event" @option:selected="selectedEvents" label="title" :clearable="false" input-id="event" />
          </b-form-group>
        </b-col>
        <b-col cols="12" xl="4" lg="3" md="12" sm="12">
          <b-form-group>
            <v-select id="event" v-model="selectedSchool" :options="schools" :reduce="(val) => val" :filterable="true" placeholder="Search by school" @option:selected="filterBySchool" label="name" :clearable="false" input-id="event" />
          </b-form-group>
        </b-col>
        <b-col cols="12" xl="4" lg="3" md="12" sm="12">
          <b-form-group>
            <b-input-group>
              <flat-pickr :disabled="resultData.observers" placeholder="Date Range" v-model="rangeDate" onClose="testClose" class="form-control" :config="{ mode: 'range' }" />
              <b-input-group-append>
                <b-button @click="clearDate()" variant="warning">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col class="col-auto" cols="3" xl="3" md="12" sm="12">
          <b-form-group>
            <!-- <b-button @click="downloadPdf()" v-show="$Can('produce_a_report')" class="mr-2" block variant="outline-info" :disabled="!resultData.observers">
                Generate Report
              </b-button> -->
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-2" align-h="center">
        <b-col class="col-auto" cols="9" xl="9" lg="9" md="12" sm="12">
          <b-card-body v-if="chartData.labels.length > 0" style="padding-top:0!important">
            <!-- <div
              style="margin-bottom: 0.5rem;display: flex;justify-content: end"
            >
              <feather-icon
                icon="HelpCircleIcon"
                v-b-tooltip.hover
                id="tooltip-button-show-event2"
              />
              
              <b-tooltip ref="tooltip-show" target="tooltip-button-show-event2">
                <span>The purpose of this chart:</span
                ><strong
                  ><br />⁍ It shows the average of the scores given by the
                  observers who made the selected survey.<br />⁍ At the same time, the
                  observers below and above the average are indicated in the
                  table.</strong
                >
              </b-tooltip>
            </div> -->
            <chartjs-component-bar-chart :height="400" :data="chartData" :refreshChart="refreshChart" :options="chartDataOptions" />
          </b-card-body>
        </b-col>

        <b-col class="col-auto" cols="3" xl="3" lg="3" md="12" sm="12">
          <table class="table table-bordered" small>
            <tbody class="text-center">
              <tr class="title">
                <th class="bg-dark text-white noborderright">
                  Total Observer Score
                </th>
                <!-- <th colspan="2" class="noborderright">
                  Company Benchmark
                </th> -->
              </tr>
              <tr class="textvalue">
                <td class="bg-light-secondary">
                  {{ resultTableData.tcs !== null ? resultTableData.tcs : 'N/A' }}
                </td>
                <!-- <td colspan="2" class="bg-light-secondary">
                  {{ resultTableData.cb !== null ? resultTableData.cb : 'N/A' }}
                </td> -->
              </tr>

              <tr class="title">
                <th class="bg-dark text-white">Mean/Average Score</th>
              </tr>
              <tr class="textvalue">
                <td class="bg-light-secondary">
                  <b style="color:red">{{ resultTableData.mas !== null ? resultTableData.mas : 'N/A' }}</b>
                </td>
              </tr>

              <!-- <tr class="title">
                <th class="bg-dark text-white">Standart Devition</th>
              </tr>
              <tr class="textvalue">
                <td class="bg-light-secondary">
                  {{ resultTableData.sd !== null ? resultTableData.sd : 'N/A' }}
                </td>
              </tr> -->

              <tr class="title">
                <th class="bg-dark text-white">
                  Number of staff equal above average
                </th>
              </tr>
              <tr class="textvalue">
                <td class="bg-light-secondary">
                  {{ resultTableData.seaa !== 0 ? resultTableData.seaa : 0 }}
                </td>
              </tr>

              <tr class="bg-dark text-white">
                <th>Number of staff below average</th>
              </tr>
              <tr class="textvalue">
                <td class="bg-light-secondary">
                  {{ resultTableData.sba !== 0 ? resultTableData.sba : 0 }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
        <!-- <p style="font-weight:bold;font-style:italic">The purpose of this chart: It shows the average of the scores given by the observers who made the selected survey. At the same time, the observers below and above the average are indicated in the table.</p> -->
      </b-row>

      <b-row align-h="start">
        <b-col cols="12" xl="3" lg="3" md="4" sm="12">
          <b-form-group>
            <b-button type="submit" @click="showArchiveRecords()" variant="warning" style="max-height: 38px;">
              {{ archive == 'N' ? 'Show with archive records' : 'Hide with archive records' }}
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <template #overlay v-if="download">
      <div class="text-center">
        <!-- <b-icon icon="stopwatch" font-scale="3" animation="cylon">
              
            </b-icon> -->
        <p id="cancel-label"><feather-icon icon="DownloadIcon" /> Report creating please wait...</p>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import { BCol, BRow, BTab, BTabs, BCard, BAlert, BLink, VBModal, VBTooltip, BTooltip } from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';
import ChartjsComponentBarChart from './../charts-components/ChartjsComponentBarChart.vue';

import store from '@/store';
import settingsStoreModule from '../../settingsStoreModule';
import ImageCropper from '@core/components/image-cropper/ImageCropper.vue';
import { required, alphaNum, email } from '@validations';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us';
import router from '@/router';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import axiosIns from '@/libs/axios';
import _ from 'lodash';
import { t } from '@/@core/libs/i18n/utils';
import { backgroundColor } from 'echarts/lib/theme/dark';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { $themeColors } from '@themeConfig';

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,
    BTooltip,

    vSelect,
    Cleave,
    ToastificationContent,
    ImageCropper,
    ValidationProvider,
    ValidationObserver,
    flatPickr,

    ChartjsComponentBarChart,
  },

  directives: {
    Ripple,
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
  },

  props: ['StatTrigger'],

  data: function() {
    return {
      resultData: {
        event: {
          groups: [],
        },
      },
      resultTableData: {
        tcs: null,
        cb: null,
        mas: null,
        sd: null,
        seaa: 0,
        sba: 0,
      },
      archive: 'N',
      rangeDate: null,
      filterDateStart: null,
      filterDateEnd: null,
      baseURL: store.state.app.baseURL,
      required,
      alphaNum,
      email,
      dropdownLoading: false,
      download: false,
      formShow: false,
      schools: [],
      selectedSchool: null,
      groups: [],
      allGroupsData: [],
      filteredQuestions: [],
      selectedEvent: null,
      ans: null,
      summaryList: null,
      answerTypes: [1, 2, 3, 4, 'Not Observed'],
      eventTypes: [],
      refreshChart: false,
      chartData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: '#84D0FF',
            borderColor: 'transparent',
          },
        ],
      },

      chartDataOptions: {
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: 'bottom',
          },
        },

        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: false,
        },
        lineAt: 3, // Total Avarage line is here

        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: 'rgba(0, 0, 0, 0.25)',
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        zoom: {
          enabled: true,
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: true,
                color: 'rgba(200, 200, 200, 0.2)',
                zeroLineColor: 'rgba(200, 200, 200, 0.2)',
              },
              scaleLabel: {
                display: false,
              },
              ticks: {
                fontColor: '#6e6b7b',
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                color: 'rgba(200, 200, 200, 0.2)',
                zeroLineColor: 'rgba(200, 200, 200, 0.2)',
              },
              ticks: {
                stepSize: 1,
                min: 0,
                max: 4,
                fontColor: '#6e6b7b',
              },
            },
          ],
        },
      },
    };
  },
  mounted() {
    //console.log('mounted')
    this.getSurveyResults();
  },

  created() {},

  watch: {
    rangeDate: {
      handler: function(val, before) {
        if (val != null) {
          if (val.includes('to')) {
            this.filterDateStart = this.rangeDate.split(' to ')[0];
            this.filterDateEnd = this.rangeDate.split(' to ')[1];

            this.selectedEvents();
          }
        }
      },
    },
    StatTrigger: {
      handler: function(val, before) {
        if (val === 'ObserverStats') {
          this.getSurveyResults();
          this.resultTableData = {
            tcs: null,
            cb: null,
            mas: null,
            sd: null,
            seaa: 0,
            sba: 0,
          };
        }
      },
    },
  },
  methods: {
    showArchiveRecords() {
      if (this.archive == 'Y') {
        this.archive = 'N';

        this.selectedEvents();
      } else {
        this.archive = 'Y';

        this.selectedEvents();
      }
    },

    downloadPdf() {
      this.formShow = true;
      this.download = true;
      let testArray = [];
      let downFilteredQuestions = [];
      let groupAnsewrs = [];

      let aaadd = _(this.resultData.questionAnswers)
        .groupBy('questionId')
        .map((question, id, index) => {
          return question[0];
        })
        .value();

      this.resultData.event.groups.forEach((element) => {
        downFilteredQuestions = _.filter(aaadd, {
          groupId: element.id,
        });

        let count = 0;
        if (downFilteredQuestions.length > 0) {
          downFilteredQuestions.forEach((question) => {
            this.resultData.questionAndAnswers.forEach((answer) => {
              if (question.questionId === answer.questionId) {
                question.itemScore = answer.commentAnswersAvarage.toFixed(2);
                downFilteredQuestions[0].domainScore = ((count += answer.commentAnswersAvarage) / downFilteredQuestions.length).toFixed(2);
              }
            });
          });
        }

        testArray.push(downFilteredQuestions);
      });
      store
        .dispatch('settings/cpatPdf', {
          data: testArray,
          groups: this.resultData.event.groups,
        })
        .then((response) => {
          if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Profit Loss Statement.pdf');
            document.body.appendChild(link);
            link.click();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Report is created!',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
            this.download = false;
            this.formShow = false;
          } else {
            this.formShow = false;
            this.download = false;
          }
        })
        .catch((error) => {
          this.formShow = false;
          this.download = false;
        });
    },

    getLastEvent() {
      this.formShow = true;
      store
        .dispatch('settings/getLastEventItem', [])
        .then((res) => {
          if (res.data) {
            this.selectedEvent = res.data;
            this.selectedEvents(res.data);
          }
        })
        .catch((error) => {
          console.log(error);
          this.formShow = false;
        });
    },
    clearDate() {
      if (this.rangeDate != null && this.rangeDate != '') {
        this.filterDateStart = null;
        this.filterDateEnd = null;
        this.rangeDate = null;
        this.selectedEvents();
      }
    },
    filterBySchool() {
      this.selectedEvents(undefined);
    },
    getSurveyResults() {
      this.formShow = true;

      let filter = {
        event: this.selectedEvent ? this.selectedEvent : null,
        filterDate: {
          startDate: this.filterDateStart,
          endDate: this.filterDateEnd,
        },
        archive: this.archive,
      };
      store
        .dispatch('settings/getAllSurveyResults', filter)
        .then((res) => {
          if(res.data.length > 0){

            this.summaryList = res.data[0].summary;
          this.totalRows = res.data.length;
          this.getLastEvent();
          res.data.forEach((element) => {
            this.eventTypes.push(element.event);
          });

          this.eventTypes = _.uniqBy(this.eventTypes, function(e) {
            return e.id;
          });

          
          }
         
          this.formShow = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectedEvents(event) {
      if (event != undefined) {
        this.selectedSchool = null;
      }

      let filter = {
        event: event == undefined ? this.selectedEvent : event,
        filterDate: {
          startDate: this.filterDateStart,
          endDate: this.filterDateEnd,
        },
        archive: this.archive,
        school: this.selectedSchool && this.selectedSchool !== 'All School' ? this.selectedSchool : null,
      };
      store
        .dispatch('settings/getSurveysObsWithId', filter)
        .then((res) => {
          this.resultData = res.data.data.observers;
          let surveyTotalQues = 0;
          let totalaa = 0;
          let allQuestions = [];

          this.resultTableData = {
            tcs: null,
            cb: null,
            mas: null,
            sd: null,
            seaa: 0,
            sba: 0,
          };

          this.selectedEvent.groups.forEach((element) => {
            element.questions.forEach((ques) => {
              if (ques.status == 'A' && ques.commentAnswer != 'Not Observed') {
                allQuestions.push(ques);
              }
            });
          });

          //Total avarage items
          let totalAvaragesArray = [];
          let totalAvarageObserversCount = res.data.data.observers.length;

          this.resultData.forEach((e) => {
            totalAvaragesArray.push(parseFloat(e.totalAvarage));
          });

          this.resultData = _(this.resultData)
            .groupBy('observer')
            .map((observer, id, index) => ({
              observerName: observer[0].observer,
              totalSum: _.sumBy(observer, function(o) {
                // totalaa = totalaa + 1;

                return o.sumTotal;
              }),

              totalSumAverage: _.sumBy(observer, function(o) {
                totalaa = totalaa + 1;

                return o.totalAvarage;
              }),
              surveyCount: totalaa,
              totalCount: totalaa * allQuestions.length,
              //lastTotalSumAverage: totalSumAverage /totalaa,
              aaaa: _.sumBy(observer, function(o) {
                totalaa = 0;
              }),
            }))
            .value();

          this.chartData.labels = [];
          this.chartData.datasets[0].data = [];

          let totalAvarage = 0;

          //calculate total avarage
          this.resultData.forEach((e) => {
            e.totalAvarage = (e.totalSum / e.totalCount).toFixed(2);
            totalAvarage += parseFloat((e.totalSum / e.totalCount).toFixed(2));
            e.lastTotalSumAverage = e.totalSumAverage / e.surveyCount.toFixed(2);
          });

          //order by total avarage observer
          this.resultData = _.orderBy(this.resultData, ['lastTotalSumAverage'], ['desc']);

          this.resultData.forEach((element) => {
            this.chartData.labels.push(element.observerName);

            this.chartData.datasets[0].data.push(element.lastTotalSumAverage.toFixed(2));
            //element.totalAvarage = (element.totalSum / element.totalCount).toFixed(2);

            // this.$emit('update:chartData', this.chartData);

            //calculate table data
            this.resultTableData.tcs += element.totalSum;
            this.resultTableData.cb += element.totalSum;
          });

          //Calculate total avarage score
          this.chartData.datasets[0].totalAvarage = (_.sum(totalAvaragesArray) / totalAvarageObserversCount).toFixed(2);

          // calculate table below and above staff
          this.resultData.forEach((e) => {
            if (e.lastTotalSumAverage < this.chartData.datasets[0].totalAvarage) {
              this.resultTableData.sba += 1;
            } else {
              this.resultTableData.seaa += 1;
            }
          });

          //calculate table total avarage
          this.resultTableData.mas = this.chartData.datasets[0].totalAvarage;

          //total avarage equal to line
          this.chartDataOptions.lineAt = this.chartData.datasets[0].totalAvarage;
          //set chart colour
          this.chartData.datasets[0].backgroundColor = res.data.data.chartColour;

          this.refreshChart = !this.refreshChart;

          this.schools = [];
          this.schools.push('All School');

          if (res.data.data.schools != undefined) {
            res.data.data.schools.forEach((s) => {
              this.schools.push(s.schoolName);
            });
          }
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.left-align .nav-tabs .nav-link {
  justify-content: left !important;
}
</style>
