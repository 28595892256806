<template>
  <b-row>
    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
      <b-card>
        <b-tabs justified pills card active-nav-item-class="font-weight-bold text-uppercase text-white" nav-class="nav-center" class="verticalTab" small>
          <hr />
          <b-tab title="Survey Based Stats" @click="currentStatTabComponent = 'SurveyBasedStat'" ref="SurveyBasedStat">
            <template slot="title">
              Survey Based Stats
              <feather-icon icon="AlignCenterIcon" class="ml-1" />
            </template>
            <b-col cols="12" xl="12" md="12" sm="12">
              <SurveyBasedStat :StatTrigger="currentStatTabComponent" class="animate__animated animate__fadeIn" />
            </b-col>
          </b-tab>

          <b-tab title="Group Based Stats" @click="currentStatTabComponent = 'GroupBasedStat'">
            <template slot="title">
              Group Based Stats
              <feather-icon icon="SlidersIcon" class="ml-1" />
            </template>
            <b-col cols="12" xl="12" md="12" sm="12">
              <GroupBasedStat :StatTrigger="currentStatTabComponent" class="animate__animated animate__fadeIn" />
            </b-col>
          </b-tab>

          <!-- <b-tab
          title="Question Based Stats"
          @click="currentStatTabComponent = 'QuestionBasedStat'"
        >
          <template slot="title">
            Question Based Stats
            <feather-icon icon="BarChart2Icon" class="ml-1" />
          </template>
          <b-col cols="12" xl="12" md="12" sm="12">
            <QuestionBasedStat
              :StatTrigger="currentStatTabComponent"
              class="animate__animated animate__fadeIn"
            />
          </b-col>
        </b-tab> -->
        </b-tabs>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BCol, BRow, BTab, BTabs, BCard, BAlert, BLink, VBModal } from 'bootstrap-vue';
import 'animate.css';

import SurveyBasedStat from './SurveyBasedStat.vue';
import GroupBasedStat from './GroupBasedStat.vue';
import QuestionBasedStat from './QuestionBasedStat.vue';

export default {
  components: {
    SurveyBasedStat,
    GroupBasedStat,
    QuestionBasedStat,
    BTab,
    BTabs,
    BRow,
    BCol,
  },
  props: ['trigger'],
  data() {
    return {
      currentStatTabComponent: 'SurveyBasedStat',
    };
  },
  mounted() {
    //this.currentStatTabComponent = "SurveyBasedStat";
  },
  watch: {
    trigger: {
      handler: function(val, before) {
        if (val === 'SurveyStats') {
          //alt props  StatTrigger'ı ilk başta tetiklenmediği için bu şekilde alt func erişip tetiklettim. (Burak)
          this.$refs.SurveyBasedStat.$children[0].getSurveyBasedResults();
          this.currentStatTabComponent = 'SurveyBasedStat';
        }
      },
    },
  },
};
</script>

<style scoped>
.verticalTab >>> .nav-pills .nav-link.active {
  /* background: linear-gradient(118deg, #ffc107, rgba(240, 229, 7, 0.919)); */
  background: #fcb416;
  border-color: #fcb416;
  color: whitesmoke !important;
}

.verticalTab >>> .nav-pills .nav-link {
  background: white;
  border-color: #fcb416;
  color: #ffc107;
  font-weight: 500;
  margin: 0 0.5rem 0 0.5rem;
}

.verticalTab >>> .card-header {
  padding-bottom: 0 !important;
}
</style>
