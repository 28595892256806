<template>
  <b-row>
    <b-col cols="12" xl="12" md="12" sm="12">
      <b-tabs justified pills active-nav-item-class="font-weight-bold text-uppercase text-white" nav-class="nav-center">
        <b-tab active title="Team Stats" @click="currentTabComponent = 'TeamStats'">
          <template slot="title">
            Team Stats
            <feather-icon icon="BarChart2Icon" class="ml-1" />
          </template>
          <b-col cols="12" xl="12" md="12" sm="12">
            <TeamStats :trigger="currentTabComponent" class="animate__animated animate__fadeIn" />
          </b-col>
        </b-tab>

        <b-tab title="Survey Stats" @click="currentTabComponent = 'SurveyStats'">
          <template slot="title">
            Survey Stats
            <feather-icon icon="BarChart2Icon" class="ml-1" />
          </template>
          <b-col cols="12" xl="12" md="12" sm="12">
            <SurveyStats :trigger="currentTabComponent" class="animate__animated animate__fadeIn" />
          </b-col>
        </b-tab>

        <b-tab title="Team Stats" @click="currentTabComponent = 'SchoolStats'">
          <template slot="title">
            School Stats
            <feather-icon icon="BarChart2Icon" class="ml-1" />
          </template>
          <b-col cols="12" xl="12" md="12" sm="12">
            <SchoolDistributionStats :trigger="currentTabComponent" class="animate__animated animate__fadeIn" />
          </b-col>
        </b-tab>
      </b-tabs>
    </b-col>
  </b-row>
</template>

<script>
import SurveyStats from './Survey-Stats/SurveyStats.vue';
import TeamStats from './Team-Stats/TeamStats.vue';
import SchoolDistributionStats from './School-Stats/SchoolDistributionStats.vue';
import 'animate.css';

import { BCol, BRow, BTab, BTabs, BCard, BAlert, BLink, VBModal } from 'bootstrap-vue';

export default {
  components: {
    SurveyStats,
    TeamStats,
    SchoolDistributionStats,
    BTab,
    BTabs,
    BRow,
    BCol,
  },
  mounted() {
    this.currentTabComponent = 'TeamStats';
  },
  data() {
    return {
      currentTabComponent: 'TeamStats',
    };
  },
  watch: {
    currentTabComponent: {
      handler: function(val, before) {
        // console.log(val)
      },
    },
  },
};
</script>
