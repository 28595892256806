<template>
  <b-row>
    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
      <b-card>
        <b-tabs justified pills card active-nav-item-class="font-weight-bold text-uppercase text-white" nav-class="nav-center" class="verticalTab" small>
          <hr />
          <b-tab active title="Observer Stats" @click="currentStatTabComponent = 'ObserverStats'">
            <template slot="title">
              Observer Stats
              <feather-icon icon="BarChart2Icon" class="ml-1" />
            </template>
            <b-col cols="12" xl="12" md="12" sm="12">
              <ObserverStats :StatTrigger="currentStatTabComponent" class="animate__animated animate__fadeIn" />
            </b-col>
          </b-tab>

          <b-tab title="Teacher Stats" @click="currentStatTabComponent = 'TeacherStats'">
            <template slot="title">
              Teacher Stats
              <feather-icon icon="BarChart2Icon" class="ml-1" />
            </template>
            <b-col cols="12" xl="12" md="12" sm="12">
              <TeacherStats :StatTrigger="currentStatTabComponent" class="animate__animated animate__fadeIn" />
            </b-col>
          </b-tab>

          <b-tab title="Observer Growth Standard Deviation" @click="currentStatTabComponent = 'ObserverStandardDeviation'">
            <template slot="title">
              Observer Growth Standard Deviation
              <feather-icon icon="BarChart2Icon" class="ml-1" />
            </template>
            <b-col cols="12" xl="12" md="12" sm="12">
              <ObserverStandardDeviation :StatTrigger="currentStatTabComponent" class="animate__animated animate__fadeIn" />
            </b-col>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BCol, BRow, BTab, BTabs, BCard, BAlert, BLink, VBModal } from 'bootstrap-vue';
import 'animate.css';

import ObserverStats from './ObserverStats.vue';
import TeacherStats from './TeacherStats.vue';
import ObserverStandardDeviation from './GrowthObserverSDeviation.vue';

export default {
  components: {
    ObserverStats,
    TeacherStats,
    ObserverStandardDeviation,
    BTab,
    BTabs,
    BRow,
    BCol,
  },
  props: ['trigger'],
  data() {
    return {
      currentStatTabComponent: 'ObserverStats',
    };
  },
  mounted() {
    //this.currentStatTabComponent = "ObserverStats";
  },
  watch: {
    trigger: {
      handler: function(val, before) {
        if (val === 'TeamStats') {
          this.currentStatTabComponent = 'ObserverStats';
        }
      },
    },
  },
};
</script>

<style scoped>
.verticalTab >>> .nav-pills .nav-link.active {
  background: #fcb416;
  border: solid 3px #fcb416;
  color: #0b4666 !important;
}

.verticalTab >>> .nav-pills .nav-link {
  background: white;
  border: solid 3px #fcb416;
  color: #0b4666;
  font-weight: 500;
  margin: 0 0.5rem 0 0.5rem;
}

.verticalTab >>> .card-header {
  padding-bottom: 0 !important;
}
</style>
