<template>
  <b-overlay :show="formShow" rounded="true" :fixed="true" no-fade>
    <b-row>
      <b-alert show variant="info" dismissible class="p-1" style="width: 100%;">
        <span>The purpose of this chart:</span>
        <p>⁍ It is to indicate the percentage of the existing surveys among the surveys that have been made.</p>
      </b-alert>
    </b-row>
    <b-card>
      <b-row>
        <b-col cols="12" xl="12" lg="12" md="12" sm="12">
          <b-row class="" align-h="between">
            <b-col cols="12" xl="4" lg="4" md="11" sm="11">
              <b-form-group>
                <b-button type="submit" class="mr-2" @click="showArchiveRecords()" variant="outline-warning">
                  {{ archive == 'N' ? 'Show with archive records' : 'Hide with archive records' }}
                </b-button>
              </b-form-group>
            </b-col>

            <b-col cols="12" xl="4" lg="4" md="11" sm="11">
              <b-input-group>
                <flat-pickr :disabled="resultData.observers" placeholder="Date Range" v-model="rangeDate" onClose="testClose" class="form-control" :config="{ mode: 'range' }" />
                <b-input-group-append>
                  <b-button @click="clearDate()" variant="warning">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="!notFoundData" class="animate__animated animate__fadeIn" cols="12" xl="12" lg="12" md="12" sm="12">
              <vue-apex-charts ref="realtimeChart" type="donut" height="500" :options="donutChart.chartOptions" :series="donutChart.series" />
            </b-col>
            <b-col v-else class="text-center animate__animated animate__fadeIn mt-2" cols="12" xl="12" lg="12" md="12" sm="12">
              <h2 class="text-center">{{ notFoundText }}</h2>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import { BCol, BRow, BTab, BTabs, BCard, BAlert, BLink, VBModal, VBTooltip, BTooltip } from 'bootstrap-vue';
import 'animate.css';
import { $themeColors } from '@themeConfig';
import vSelect from 'vue-select';
import { ref, onUnmounted } from '@vue/composition-api';
import _ from 'lodash';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import settingsStoreModule from '../../settingsStoreModule';
import store from '@/store';
import VueApexCharts from 'vue-apexcharts';
import Ripple from 'vue-ripple-directive';

export default {
  components: {
    BTab,
    BTabs,
    BRow,
    BCol,
    BCard,
    BTooltip,
    VueApexCharts,

    vSelect,
    flatPickr,
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
  },

  props: ['StatTrigger'],

  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },

  data() {
    return {
      resultData: {
        event: {
          groups: [],
        },
      },
      selectedEvent: null,
      eventTypes: [],
      formShow: false,
      rangeDate: null,
      filterDateStart: null,
      filterDateEnd: null,
      totalSurveys: [],
      archive: 'N',
      notFoundText: '',
      notFoundData: false,
      donutChart: {
        series: [],
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          colors: ['#ffe700', '#FFA1A1', '#826bf8', '#00d4bd', '#24fc03', '#ffbc03', '#ff2d03', '#ff03dd', '#c403ff', '#3a03ff', '#0370ff', '#03b3ff', '#07e0d9'],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`;
            },
          },
          //   noData: {
          //     text: 'Data not found',
          //     align: 'center',
          //     verticalAlign: 'middle',
          //     offsetX: 0,
          //     offsetY: 0,
          //     style: {
          //         color: '#f4f4f4',
          //         fontSize: '14px',
          //         fontFamily: Montserrat
          //     }
          //     },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '2rem',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`;
                    },
                  },
                  total: {
                    show: true,
                    showAlways: true,
                    fontSize: '1.5rem',
                    label: 'Total',
                    formatter(w) {
                      if (w.globals.seriesTotals) {
                        return (
                          'Survey:' +
                          w.globals.seriesTotals.reduce((a, b) => {
                            return a + b;
                          }, 0)
                        );
                      }
                    },
                  },
                },
              },
            },
          },
          labels: [],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
    };
  },
  mounted() {
    //this.getSurveyBasedResults();
  },
  methods: {
    showArchiveRecords() {
      if (this.archive == 'Y') {
        this.archive = 'N';

        this.filterDate();
      } else {
        this.archive = 'Y';

        this.filterDate();
      }
    },
    getSurveyBasedResults() {
      this.formShow = true;
      const self = this;

      store
        .dispatch('settings/getSurveyBasedResults')
        .then((res) => {
          this.notFoundData = false;
          if (res.data.length > 0) {
            //firstly chart data begin must be filled
            res.data.forEach((label) => {
              this.donutChart.chartOptions.labels.push(label[0].event.title);
              this.donutChart.series.push(label.length);
            });
            //reset chart data
            this.donutChart.chartOptions.labels = [];
            this.donutChart.series = [];
            this.$refs.realtimeChart.resetSeries();
            //
            //return filled chart data
            res.data.forEach((label) => {
              this.donutChart.chartOptions.labels.push(label[0].event.title);
              this.donutChart.series.push(label.length);
            });
            //
          }

          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    filterDate() {
      let filter = {
        filterDate: {
          startDate: this.filterDateStart ? this.filterDateStart : null,
          endDate: this.filterDateEnd ? this.filterDateEnd : null,
        },
        archive: this.archive,
      };

      store
        .dispatch('settings/getFilterSurveyDate', filter)
        .then((res) => {
          if (res.data.length > 0) {
            this.notFoundData = false;

            if (res.data.length > 0) {
              //reset chart data
              this.donutChart.chartOptions.labels = [];
              this.donutChart.series = [];
              this.$refs.realtimeChart.resetSeries();
              //
              res.data.forEach((label) => {
                this.donutChart.chartOptions.labels.push(label[0].event.title);
                this.donutChart.series.push(label.length);
              });
              this.formShow = false;
            }
          } else {
            this.formShow = false;
            this.notFoundText = 'Data not found these dates.';
            this.notFoundData = true;
          }
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },
    clearDate() {
      if (this.rangeDate != null && this.rangeDate != '') {
        this.filterDateStart = null;
        this.filterDateEnd = null;
        this.rangeDate = null;
        this.filterDate();
      }
    },
  },
  watch: {
    rangeDate: {
      handler: function(val, before) {
        if (val != null) {
          if (val.includes('to')) {
            this.filterDateStart = this.rangeDate.split(' to ')[0];
            this.filterDateEnd = this.rangeDate.split(' to ')[1];

            this.filterDate();
          }
        }
      },
    },
    StatTrigger: {
      handler: function(val, before) {
        if (val === 'SurveyBasedStat') {
          this.getSurveyBasedResults();
        }
      },
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.set-icon-css .input-group {
  justify-content: end;
}
</style>
