<template>
  <b-overlay :show="formShow" rounded="true" :fixed="true" no-fade>
    <b-row>
      <b-alert show variant="info" dismissible class="p-1 w-100">
        <span>The purpose of this chart:</span>
        <p>⁍ The main goal is to show the averages of the results of the survey conducted on different days of the teachers in the school connected to the selected survey, separately.</p>
        <p>⁍ You can also filter the question groups related to the selected survey and the schools where the survey was conducted.</p>
        <p>⁍ At the same time, you can see the average of the results obtained as a result of the filtering.</p>
      </b-alert>
    </b-row>
    <b-card>
      <b-row class="">
        <b-col cols="12" xl="3" lg="3" md="12" sm="12">
          <b-form-group>
            <v-select id="event" v-model="selectedEvent" :options="eventTypes" :reduce="(val) => val" :filterable="true" placeholder="Search by event" @option:selected="selectedEvents" label="title" :clearable="false" input-id="event" />
          </b-form-group>
        </b-col>

        <b-col cols="12" xl="9" lg="9" md="12" sm="12">
          <b-form-group>
            <v-select id="group" v-model="selectedGroup" :options="eventGroups" :reduce="(val) => val" :filterable="true" placeholder="Search by group" @option:selected="filterGroups" label="name" :clearable="false" input-id="group" />
          </b-form-group>
        </b-col>

        <b-col cols="12" xl="4" lg="4" md="12" sm="12">
          <b-form-group>
            <v-select id="event" v-model="selectedSchool" :options="schools" :reduce="(val) => val" :filterable="true" placeholder="Search by school" @option:selected="filterBySchool" label="name" :clearable="false" input-id="event" />
          </b-form-group>
        </b-col>

        <b-col cols="12" xl="4" lg="4" md="12" sm="12">
          <b-form-group>
            <v-select id="teacher" v-model="selectedTeacher" :options="eventTeachers" :reduce="(val) => val" :filterable="true" placeholder="Search by CT" @option:selected="filterTeachers" label="name" :clearable="false" input-id="teacher" />
          </b-form-group>
        </b-col>

        <b-col cols="12" xl="4" lg="4" md="12" sm="12">
          <b-form-group>
            <b-input-group>
              <flat-pickr :disabled="resultData.observers" placeholder="Date Range" v-model="rangeDate" onClose="testClose" class="form-control" :config="{ mode: 'range' }" />
              <b-input-group-append>
                <b-button @click="clearDate()" variant="warning">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col class="col-auto" cols="3" xl="3" md="12" sm="12">
          <b-form-group> </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-card-body v-if="chartData.labels.length > 0">
          <chartjs-component-bar-teacher-chart v-if="notFoundHide" :height="400" :data="chartData" :refreshChart="refreshChart" :options="chartDataOptions" />

          <h3 class="text-center animate__animated animate__fadeIn" v-else>
            {{ notFoundText }}
          </h3>
        </b-card-body>
      </b-row>

      <b-row align-h="start">
        <b-col cols="12" xl="3" lg="3" md="4" sm="12">
          <b-form-group>
            <b-button type="submit" @click="showArchiveRecords()" variant="warning" style="max-height: 38px;">
              {{ archive == 'N' ? 'Show with archive records' : 'Hide with archive records' }}
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <template #overlay v-if="download">
      <div class="text-center">
        <!-- <b-icon icon="stopwatch" font-scale="3" animation="cylon">
            
          </b-icon> -->
        <p id="cancel-label"><feather-icon icon="DownloadIcon" /> Report creating please wait...</p>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import { BCol, BRow, BTab, BTabs, BCard, BAlert, BLink, VBModal, VBTooltip, BTooltip } from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';
import ChartjsComponentBarTeacherChart from './../charts-components/ChartjsComponentBarTeacherChart.vue';

import store from '@/store';
import settingsStoreModule from '../../settingsStoreModule';
import ImageCropper from '@core/components/image-cropper/ImageCropper.vue';
import { required, alphaNum, email } from '@validations';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us';
import router from '@/router';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import axiosIns from '@/libs/axios';
import _ from 'lodash';
import { t } from '@/@core/libs/i18n/utils';
import { backgroundColor } from 'echarts/lib/theme/dark';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { $themeColors } from '@themeConfig';

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,
    BTooltip,

    vSelect,
    Cleave,
    ToastificationContent,
    ImageCropper,
    ValidationProvider,
    ValidationObserver,
    flatPickr,

    ChartjsComponentBarTeacherChart,
  },

  directives: {
    Ripple,
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
  },

  props: ['StatTrigger'],

  data: function() {
    return {
      resultData: {
        event: {
          groups: [],
        },
      },
      rangeDate: null,
      filterDateStart: null,
      filterDateEnd: null,
      baseURL: store.state.app.baseURL,
      required,
      alphaNum,
      email,
      dropdownLoading: false,
      download: false,
      formShow: false,
      schools: [],
      selectedSchool: null,
      groups: [],
      allGroupsData: [],
      filteredQuestions: [],
      selectedEvent: null,
      selectedGroup: null,
      selectedTeacher: null,
      archive: 'N',
      ans: null,
      summaryList: null,
      answerTypes: [1, 2, 3, 4, 'Not Observed'],
      eventTypes: [],
      eventGroups: [],
      eventTeachers: [],
      refreshChart: false,
      notFoundText: '',
      notFoundHide: true,
      chartData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: '#84D0FF',
            borderColor: 'transparent',
          },
        ],
      },

      chartDataOptions: {
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: 'bottom',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: false,
        },
        lineAt: 3, // Total Avarage line is here
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: 'rgba(0, 0, 0, 0.25)',
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        scales: {
          xAxes: [
            {
              display: false,
              gridLines: {
                display: false,
                color: 'rgba(200, 200, 200, 0.2)',
                zeroLineColor: 'rgba(200, 200, 200, 0.2)',
              },
              scaleLabel: {
                display: false,
              },
              ticks: {
                fontColor: '#6e6b7b',
                fontSize: 10,
                crossAlign: 'center',
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                color: 'rgba(200, 200, 200, 0.2)',
                zeroLineColor: 'rgba(200, 200, 200, 0.2)',
              },
              ticks: {
                stepSize: 1,
                min: 0,
                max: 4,
                fontColor: '#6e6b7b',
              },
            },
          ],
        },
      },
    };
  },
  mounted() {
    //this.getSurveyResults();
  },

  created() {},

  watch: {
    rangeDate: {
      handler: function(val, before) {
        if (val != null) {
          if (val.includes('to')) {
            this.filterDateStart = this.rangeDate.split(' to ')[0];
            this.filterDateEnd = this.rangeDate.split(' to ')[1];

            this.selectedEvents();
          }
        }
      },
    },
    StatTrigger: {
      handler: function(val, before) {
        if (val === 'TeacherStats') {
          this.getSurveyResults();
        }
      },
    },
  },
  methods: {
    showArchiveRecords() {
      if (this.archive == 'Y') {
        this.archive = 'N';

        this.selectedEvents();
      } else {
        this.archive = 'Y';

        this.selectedEvents();
      }
    },

    downloadPdf() {
      this.formShow = true;
      this.download = true;
      let testArray = [];
      let downFilteredQuestions = [];
      let groupAnsewrs = [];

      let aaadd = _(this.resultData.questionAnswers)
        .groupBy('questionId')
        .map((question, id, index) => {
          return question[0];
        })
        .value();

      this.resultData.event.groups.forEach((element) => {
        downFilteredQuestions = _.filter(aaadd, {
          groupId: element.id,
        });

        let count = 0;
        if (downFilteredQuestions.length > 0) {
          downFilteredQuestions.forEach((question) => {
            this.resultData.questionAndAnswers.forEach((answer) => {
              if (question.questionId === answer.questionId) {
                question.itemScore = answer.commentAnswersAvarage.toFixed(2);
                downFilteredQuestions[0].domainScore = ((count += answer.commentAnswersAvarage) / downFilteredQuestions.length).toFixed(2);
              }
            });
          });
        }

        testArray.push(downFilteredQuestions);
      });
      store
        .dispatch('settings/cpatPdf', {
          data: testArray,
          groups: this.resultData.event.groups,
        })
        .then((response) => {
          if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Profit Loss Statement.pdf');
            document.body.appendChild(link);
            link.click();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Report is created!',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
            this.download = false;
            this.formShow = false;
          } else {
            this.formShow = false;
            this.download = false;
          }
        })
        .catch((error) => {
          this.formShow = false;
          this.download = false;
        });
    },

    getLastEvent() {
      this.formShow = true;
      store
        .dispatch('settings/getLastEventItem', [])
        .then((res) => {
          if (res.data) {
            this.selectedEvent = res.data;
            this.selectedEvents(res.data);
          }
        })
        .catch((error) => {
          console.log(error);
          this.formShow = false;
        });
    },
    clearDate() {
      if (this.rangeDate != null && this.rangeDate != '') {
        this.filterDateStart = null;
        this.filterDateEnd = null;
        this.rangeDate = null;
        this.selectedEvents();
      }
    },
    filterBySchool() {
      this.selectedEvents(undefined);
    },
    getSurveyResults() {
      this.formShow = true;

      let filter = {
        event: this.selectedEvent ? this.selectedEvent : null,
        filterDate: {
          startDate: this.filterDateStart,
          endDate: this.filterDateEnd,
        },
        archive: this.archive,
      };

      store
        .dispatch('settings/getAllSurveyResults', filter)
        .then((res) => {

          if(res.data.length > 0){
            
            this.summaryList = res.data[0].summary;
          this.totalRows = res.data.length;
          this.getLastEvent();
          res.data.forEach((element) => {
            this.eventTypes.push(element.event);
          });

          this.eventTypes = _.uniqBy(this.eventTypes, function(e) {
            return e.id;
          });
          }

          this.formShow = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    filterGroups(group) {
      let teacherEmail = null;
      let teacherName = null;
      let teacherSurname = null;

      let filterTeacher = null;

      if (this.selectedTeacher != '' && this.selectedTeacher != undefined && this.selectedTeacher != null) {
        teacherEmail = this.selectedTeacher.substring(this.selectedTeacher.indexOf('(') + 1, this.selectedTeacher.lastIndexOf(')'));
        teacherName = this.selectedTeacher.split('-')[0];
        teacherSurname = this.selectedTeacher.substring(this.selectedTeacher.indexOf('-') + 1, this.selectedTeacher.lastIndexOf('('));

        filterTeacher = {
          teacherEmail: teacherEmail ? teacherEmail : null,
          teacherFirstName: teacherName ? teacherName : null,
          teacherLastName: teacherSurname ? teacherSurname : null,
        };
      }

      let filter = {
        // group: group == undefined ? this.selectedGroup : group.name,

        filterDate: {
          startDate: this.filterDateStart,
          endDate: this.filterDateEnd,
        },
        school: this.selectedSchool && this.selectedSchool !== 'All School' ? this.selectedSchool : null,
        group: this.selectedGroup && this.selectedGroup !== 'All Group' ? this.selectedGroup.name : null,
        teacher: filterTeacher,
        event: this.selectedEvent,
        archive: this.archive,
      };

      store
        .dispatch('settings/getCTResultsGruopId', filter)
        .then((res) => {
          this.resultData = res.data.data.observers;
          let surveyTotalQues = 0;
          let totalaa = 0;
          let allQuestions = [];

          let filtreType = 'observer';
          if (this.selectedGroup == 'All Groups') {
            filtreType = 'id';
          } else {
            filtreType = 'observer';
          }

          //Total average items
          let totalAvaragesArray = [];
          let totalAvarageObserversCount = res.data.data.observers.length;

          this.resultData.forEach((e) => {
            totalAvaragesArray.push(parseFloat(e.totalAverage));
          });

          this.resultData = _(this.resultData)
            .groupBy('id')
            .map((observer, id, index) => ({
              observerName: observer[0].observer,

              aaaa: _.sumBy(observer, function(o) {
                totalaa = totalaa + o.totalAverage;
                surveyTotalQues = surveyTotalQues + 1;
              }),

              totalAverage: totalaa / surveyTotalQues,

              aaaa: _.sumBy(observer, function(o) {
                totalaa = 0;
                surveyTotalQues = 0;
              }),

              // totalAverage: observer[0].totalAverage,
            }))
            .value();

          //sort by date
          this.resultData.sort(function compare(a, b) {
            var dateA = new Date(a.observerName);
            var dateB = new Date(b.observerName);
            return dateA - dateB;
          });

          this.chartData.labels = [];
          this.chartData.datasets[0].data = [];

          //order by total avarage observer
          this.resultData = _.orderBy(this.resultData, ['totalAvarage'], ['desc']);

          this.resultData.forEach((element) => {
            this.chartData.labels.push(element.observerName);
            if (element.totalAverage != 0) {
              this.chartData.datasets[0].data.push(element.totalAverage.toFixed(2));
            }

            // this.$emit('update:chartData', this.chartData);
          });

          if (this.chartData.datasets[0].data.length > 0) {
            this.notFoundHide = true;
            this.refreshChart = !this.refreshChart;
          } else {
            this.notFoundHide = false;
            this.notFoundText = 'No data found in this group';
          }

          //Calculate total avarage score
          this.chartData.datasets[0].totalAvarage = (_.sum(totalAvaragesArray) / totalAvarageObserversCount).toFixed(2);
          //total avarage equal to line
          this.chartDataOptions.lineAt = this.chartData.datasets[0].totalAvarage;

          this.schools = [];
          this.schools.push('All School');

          if (res.data.data.schools != undefined) {
            res.data.data.schools.forEach((s) => {
              this.schools.push(s.schoolName);
            });
          }

          this.eventGroups = [];

          this.eventGroups.push('All Groups');

          if (res.data.data.groups != undefined) {
            res.data.data.groups.forEach((g) => {
              this.eventGroups.push(g);
            });
          }

          // this.eventTeachers = [];

          // this.eventTeachers.push('All CT');

          // if (res.data.data.teachers != undefined) {
          //   res.data.data.teachers.forEach((t) => {
          //     this.eventTeachers.push(t);
          //   });
          // }
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    filterTeachers(teacher) {
      let teacherEmail = null;
      let teacherName = null;
      let teacherSurname = null;

      let filterTeacher = null;

      if (this.selectedTeacher != '' && this.selectedTeacher != undefined && this.selectedTeacher != null) {
        teacherEmail = teacher.substring(teacher.indexOf('(') + 1, teacher.lastIndexOf(')'));
        teacherName = this.selectedTeacher.split('-')[0];
        teacherSurname = teacher.substring(teacher.indexOf('-') + 1, teacher.lastIndexOf('('));

        filterTeacher = {
          teacherEmail: teacherEmail ? teacherEmail : null,
          teacherFirstName: teacherName ? teacherName : null,
          teacherLastName: teacherSurname ? teacherSurname : null,
        };
      }

      let filter = {
        filterDate: {
          startDate: this.filterDateStart,
          endDate: this.filterDateEnd,
        },
        school: this.selectedSchool && this.selectedSchool !== 'All School' ? this.selectedSchool : null,
        group: this.selectedGroup && this.selectedGroup !== 'All Group' ? this.selectedGroup.name : null,
        teacher: filterTeacher,
        event: this.selectedEvent,
        archive: this.archive,
      };

      store
        .dispatch('settings/getCTResultsByTeacher', filter)
        .then((res) => {
          this.resultData = res.data.data.observers;
          let surveyTotalQues = 0;
          let totalaa = 0;
          let allQuestions = [];

          let filtreType = 'observer';
          if (this.selectedTeacher == 'All CT') {
            filtreType = 'id';
          } else {
            filtreType = 'observer';
          }

          //Total average items
          let totalAvaragesArray = [];
          let totalAvarageObserversCount = res.data.data.observers.length;

          this.resultData.forEach((e) => {
            totalAvaragesArray.push(parseFloat(e.totalAverage));
          });

          this.resultData = _(this.resultData)
            .groupBy(filtreType)
            .map((observer, id, index) => ({
              observerName: observer[0].observer,

              aaaa: _.sumBy(observer, function(o) {
                totalaa = totalaa + o.totalAverage;
                surveyTotalQues = surveyTotalQues + 1;
              }),

              totalAverage: totalaa / surveyTotalQues,

              aaaa: _.sumBy(observer, function(o) {
                totalaa = 0;
                surveyTotalQues = 0;
              }),

              // totalAverage: observer[0].totalAverage,
            }))
            .value();

          //sort by date
          this.resultData.sort(function compare(a, b) {
            var dateA = new Date(a.observerName);
            var dateB = new Date(b.observerName);
            return dateA - dateB;
          });

          this.chartData.labels = [];
          this.chartData.datasets[0].data = [];

          //order by total avarage observer
          this.resultData = _.orderBy(this.resultData, ['totalAvarage'], ['desc']);

          this.resultData.forEach((element) => {
            this.chartData.labels.push(element.observerName);
            if (element.totalAverage != 0) {
              this.chartData.datasets[0].data.push(element.totalAverage.toFixed(2));
            }

            // this.$emit('update:chartData', this.chartData);
          });

          if (this.chartData.datasets[0].data.length > 0) {
            this.notFoundHide = true;
            this.refreshChart = !this.refreshChart;
          } else {
            this.notFoundHide = false;
            this.notFoundText = 'No data found in this group';
          }

          //Calculate total avarage score
          this.chartData.datasets[0].totalAvarage = (_.sum(totalAvaragesArray) / totalAvarageObserversCount).toFixed(2);
          //total avarage equal to line
          this.chartDataOptions.lineAt = this.chartData.datasets[0].totalAvarage;

          this.schools = [];
          this.schools.push('All School');

          if (res.data.data.schools != undefined) {
            res.data.data.schools.forEach((s) => {
              this.schools.push(s.schoolName);
            });
          }

          this.eventGroups = [];

          this.eventGroups.push('All Groups');

          if (res.data.data.groups != undefined) {
            res.data.data.groups.forEach((g) => {
              this.eventGroups.push(g);
            });
          }

          // this.eventTeachers = [];

          // this.eventTeachers.push('All CT');

          // if (res.data.data.teachers != undefined) {
          //   res.data.data.teachers.forEach((t) => {
          //     this.eventTeachers.push(t);
          //   });
          // }
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    selectedEvents(event) {
      if (event != undefined) {
        this.selectedSchool = null;
      }

      this.selectedGroup = null;
      this.selectedTeacher = 'All CT';

      let teacherEmail = null;
      let teacherName = null;
      let teacherSurname = null;

      let filterTeacher = null;

      if (this.selectedTeacher != '' && this.selectedTeacher != undefined && this.selectedTeacher != null) {
        teacherEmail = this.selectedTeacher.substring(this.selectedTeacher.indexOf('(') + 1, this.selectedTeacher.lastIndexOf(')'));
        teacherName = this.selectedTeacher.split('-')[0];
        teacherSurname = this.selectedTeacher.substring(this.selectedTeacher.indexOf('-') + 1, this.selectedTeacher.lastIndexOf('('));

        filterTeacher = {
          teacherEmail: teacherEmail ? teacherEmail : null,
          teacherFirstName: teacherName ? teacherName : null,
          teacherLastName: teacherSurname ? teacherSurname : null,
        };
      }

      let filter = {
        event: event == undefined ? this.selectedEvent : event,
        // group: group == undefined ? this.selectedGroup : null,
        // teacher: teacher == undefined ? this.selectedTeacher : null,
        filterDate: {
          startDate: this.filterDateStart,
          endDate: this.filterDateEnd,
        },
        school: this.selectedSchool && this.selectedSchool !== 'All School' ? this.selectedSchool : null,
        group: this.selectedGroup && this.selectedGroup !== 'All Group' ? this.selectedGroup.name : null,
        teacher: filterTeacher,
        archive: this.archive,
      };

      store
        .dispatch('settings/getCTResultsEventId', filter)
        .then((res) => {
          this.resultData = res.data.data.observers;
          let surveyTotalQues = 0;
          let totalaa = 0;
          let allQuestions = [];

          let filtreType = 'observer';
          if (this.selectedTeacher == 'All CT') {
            filtreType = 'id';
          } else {
            filtreType = 'observer';
          }

          //Observer Total average items
          let totalAvaragesArray = [];
          let totalAvarageObserversCount = res.data.data.observers.length;

          this.resultData.forEach((e) => {
            totalAvaragesArray.push(parseFloat(e.totalAverage));
          });

          this.resultData = _(this.resultData)
            .groupBy(filtreType)
            .map((observer, id, index) => ({
              observerName: observer[0].observer,

              aaaa: _.sumBy(observer, function(o) {
                totalaa = totalaa + o.totalAverage;
                surveyTotalQues = surveyTotalQues + 1;
              }),

              totalAverage: totalaa / surveyTotalQues,

              aaaa: _.sumBy(observer, function(o) {
                totalaa = 0;
                surveyTotalQues = 0;
              }),

              // totalAverage: observer[0].totalAverage,
            }))
            .value();

          //sort by date
          this.resultData.sort(function compare(a, b) {
            var dateA = new Date(a.observerName);
            var dateB = new Date(b.observerName);
            return dateA - dateB;
          });

          this.chartData.labels = [];
          this.chartData.datasets[0].data = [];

          //calculate total avarage
          this.resultData.forEach((e) => {
            e.totalAvarage = (e.totalSum / e.totalCount).toFixed(2);
          });

          //Calculate Observer total avarage score
          this.chartData.datasets[0].totalAvarage = (_.sum(totalAvaragesArray) / totalAvarageObserversCount).toFixed(2);
          //total avarage equal to line
          this.chartDataOptions.lineAt = this.chartData.datasets[0].totalAvarage;

          //set chart colour
          this.chartData.datasets[0].backgroundColor = res.data.data.chartColour;

          this.resultData.forEach((element) => {
            this.chartData.labels.push(element.observerName);
            if (element.totalAverage != 0) {
              this.chartData.datasets[0].data.push(element.totalAverage.toFixed(2));
            }

            // this.$emit('update:chartData', this.chartData);
          });

          this.refreshChart = !this.refreshChart;

          this.schools = [];
          this.schools.push('All School');

          if (res.data.data.schools != undefined) {
            res.data.data.schools.forEach((s) => {
              this.schools.push(s.schoolName);
            });
          }

          this.eventGroups = [];

          this.eventGroups.push('All Groups');

          if (res.data.data.groups != undefined) {
            res.data.data.groups.forEach((g) => {
              this.eventGroups.push(g);
            });
          }

          this.eventTeachers = [];

          this.eventTeachers.push('All CT');

          if (res.data.data.teachers != undefined) {
            res.data.data.teachers.forEach((t) => {
              this.eventTeachers.push(t);
            });
          }
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.left-align .nav-tabs .nav-link {
  justify-content: left !important;
}
</style>
